import { Link } from 'gatsby'
import React from 'react'
import '../ManagerPanel/ManagerPanel.scss'
import ManagerImg from "../../images/manager-img.png"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { AreaguideBaseURL } from "../../common/site/utils"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";


const ManagerPanel = (props) => {
  const item = props.item

  let processedImages = JSON.stringify({});
  if (item.imagetransforms?.Tile_image_Transforms) {
    processedImages = item.imagetransforms.Tile_image_Transforms;
  }
  return (
    <div className='property-card manager-data'>
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
        <Link to={`${AreaguideBaseURL}${item.slug}/`}>
          <div className='property-img'>
            {item.tile_image ?
              <ImageTransform imagesources={item.tile_image?.url} renderer="srcSet" imagename="area-guides.Tile_image.tile" attr={{ alt: `${item.title} - Arabian Estates`, class: '' }} imagetransformresult={processedImages} id={item.id} format="source" />
            : <img src={ManagerImg} alt="card-img" /> }
          </div>
        </Link>
        <div className='property-content'>
          <h4>{item.title}</h4>
          <div className='manager-details'>
            <Link to={`${AreaguideBaseURL}${item.slug}/`} className='view-details'>View Guide</Link>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  )
}

export default ManagerPanel