import React from 'react'
import { Link } from "gatsby"
import moment from 'moment';
import './RecentNews.scss'
import  { NewsBaseURL } from "../../common/site/utils"

const RecentNewsGuide = (props) => {


  return (
    <div>
      {
        props.blogs && props.blogs.map((item, index) => {
          return <div key={index} className="news-content">
            <div className='ltl-news'>
            <Link to={`${NewsBaseURL}${item.slug}`}><img src={item.tile_image.url} alt='news-img' /></Link>
            </div>
            <div className='rtl-news'>
              <Link to={`${NewsBaseURL}${item.slug}`}><h4>{item.title}</h4></Link>
              <span className='data-card'>{moment(item.post_on).format("DD MMMM YYYY")}</span>
            </div>
          </div>
        })
      }
    </div>
  )
}

export default RecentNewsGuide