import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import ManagerPanel from '../ManagerPanel/AreaGuide'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../VideosSlider/VideoSlider.scss';


const SearchData = (props) => {

    const allguides = props.guides;
    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <React.Fragment>
            {allguides && allguides.length > 0 ?
                <section className={"search-banner videos-slider more-area-guides"}>
                    <Container>
                        <Row className='result-column management-wrap team-result'>
                            <h2>More Area Guides</h2>
                            <Slider {...settings}>
                                {
                                    allguides?.map((item, index) => (
                                        <ManagerPanel item={item} key={index} />
                                    ))
                                }
                            </Slider>
                        </Row>
                    </Container>
                </section> : ''
            }
        </React.Fragment>
    )
}

export default SearchData