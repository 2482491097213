import { Link } from 'gatsby'
import React from 'react'
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { aboutPage, areaguidePage } from '../common/site/constants';
import './PageNav.scss';
const PageNav = (props) => {
    return (
        <section className='page-nav p-0'>
            <ul>
                <li>
                    <Link to="/" className="nav-links">Home</Link>
                </li>
                <li>
                    <Link to={aboutPage} className="nav-links">About</Link>
                </li>
                <li>
                    <Link to={areaguidePage} className="nav-links">Area Guides</Link>
                </li>
                <li>
                    <div to="/" className="nav-links">{props.title} Area Guide</div>
                </li>
            </ul>
        </section>
    )
}

export default PageNav