import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { graphql } from "gatsby"
import 'bootstrap/scss/bootstrap.scss'
import "../scss/global.scss";
import "../scss/grunticon.scss"
import "slick-carousel/slick/slick.css";
import "../scss/custom.scss";
import "../scss/AreaGuide.scss";
import Layout from "../components/layout"
import SEO from "../components/seo"


import AreaBanner from "../components/AreaBanner/AreaGuideBanner";
import AreaContent from "../components/AreaContent/AreaContent";
import MoreAreaGuide from "../components/AreaGuide/MoreAreaGuide.js";


const GuideTemplate = (props) => {
  const GQLPage = props.data?.glstrapi?.areaGuide
  const MoreGuides = props.data?.glstrapi?.areaGuides
  return (
    <div className="land-header area-guide-full-details">
      <SEO title={GQLPage.seo_meta.title + " Area Guide | Arabian Estates"} description={`Know more about ${GQLPage.seo_meta.title} here. Contact one of our real estate agents for assistance in finding the right properties for you in ${GQLPage.seo_meta.title}.`} />
      <Layout>
        <AreaBanner {...GQLPage} />
        <AreaContent {...GQLPage} />
        <MoreAreaGuide guides={MoreGuides}/>
      </Layout>
    </div>
  )
}

export default GuideTemplate



export const pageQuery = graphql`
  query GetGuide($id: ID!) {
    glstrapi {
        areaGuide(id: $id, publicationState: LIVE) {
            imagetransforms
            slug
            title
            Content
            banner_image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 1800
                    quality: 100
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  ) 
                }
              }
            }
            seo_meta {
              description
              keywords
              image {
                alternativeText
                url
              }
              title
            }
      }
      areaGuides(where: {id_ne: $id, publish: true}, limit: 4) {
        id
        title
        slug
        tile_image {
          url
          alternativeText
        }
        imagetransforms
      }
    }
  }
`