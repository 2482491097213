
// TODO: Get all the URLs/alias from Strapi and use it here
export const URLS = {
    ABOUT: {
      name: 'About',
      alias: '/about/',
    },
    CONTACT: {
      name: 'Contact',
      alias: '/contact-arabian-estates/',
    },
}

export const aboutPage="/about/";
export const areaguidePage=aboutPage+"area-guides/";
export const teamPage=aboutPage+"our-team/";
export const newsPage=aboutPage+"latest-property-news-and-videos/"


export const ABOUT_PAGE_URL = URLS.ABOUT;
export const CONTACT_PAGE_URL = URLS.CONTACT;