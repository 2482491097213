import { Link } from 'gatsby';
import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import { SiteName } from "../../common/site/utils"
import './ContactDetails.scss';

const ContactDetails = (props) => {
  // const contactData = props.contactData;
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalModule {
          Address
          Email
          Phone
          Whatsapp
        }
      }
    }
  `);

  const global = data.glstrapi.globalModule;
  return (
    <div className='card-wrap'>
      <h2 className='top-title d-none d-xl-block'>Contact {SiteName}</h2>
      <div className='side-main-div'>
        <div className='content-wrapp'>
          {/* {contactData.image && <img src={contactData.image} alt="content-img" />} */}
          <div className='tele-wrap'>
            <span className='contact-heading'>Telephone</span>
            <a className="phone" href={`tel:${global.Phone}`}>{global.Phone}</a>
          </div>
        </div>
        <div className='content-wrapp'>
        <div className='tele-wrap'>
          <span className='contact-heading'>Email</span>
          <a className="email" href={`mailto:${global.Email}`}>{global.Email}</a>
          </div>
        </div>
      </div>
      <div className='btn-wrap'>
        <h4>Get a call back</h4>
        {/* {contactData.btnView && <Link to="/contact-arabian-estates" className="request-view">{contactData.btnView}</Link>} */}
        <Link to="/contact-arabian-estates/" className="request-callback">Request a Callback</Link>
      </div>
      <h2 className='bottom-title d-block d-xl-none'>Contact {SiteName}</h2>
    </div >
  )
}

export default ContactDetails