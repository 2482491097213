import React from 'react'
import { getSrc } from "gatsby-plugin-image"
import './AreaGuideBanner.scss'
import { Container, Row, Col } from 'react-bootstrap'
import PageNav from '../PageNav/AreaGuidePageNav'



const AreaBanner = (props) => {

  const src = getSrc(props?.banner_image?.url_sharp.childImageSharp)

  return (
    <div className='area-guide-banner' style={{ backgroundImage: `url(${src})` }}>
      <div className='bg-overlay'>
        <Container>
          <Row>
            <Col>
              <div className='content'>
                <PageNav title={props.title} />
                <h1>{props.title} <span>Area Guide</span></h1>
                {/* {parse(props.banner_content)} */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default AreaBanner