import { Link } from 'gatsby'
import React from 'react'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import './AreaContent.scss'
import AreaImg from "../../images/area-content-img.png"
import ContactDetails from '../ContactDetails/ContactDetails'
import RecentNews from '../RecentNews/RecentNewsAreaGuides'
import NewsImg1 from '../../images/news-img1.png'
import NewsImg2 from '../../images/news-img2.png'
import NewsImg3 from '../../images/news-img3.png'
import NewsImg4 from '../../images/news-img4.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { AllNews } from '../../common/queries/common_use_query';

const AreaContent = (props) => {

    const { loading: news_loading, error: news_error, data: news_data } = AllNews();
    const contactData = {
        cardHeading: 'Contact Arabian Estates',
        teleHeading: 'Telephone',
        telephone: '+971 4 324 3137',
        emailHeading: 'Email',
        email: 'info@arabianestates.ae',
        btnHead: "Get a call back",
        btnText: "Request a Callback"
    }
    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + shareurl,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }

    return (
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
            <div className='area-content'>
                <Container>
                    <Row>
                        <Col>
                            <div className='main-wrapper'>
                                <div className='lft-content'>
                                    {parse(props.Content)}
                                    <div className='social-menu'>
                                        <div className='footer-icons'>
                                            <ul>
                                                {/* <li>
                                                    <Link to="/" className="social-icon">
                                                        <i className="icon-insta-blue"></i>
                                                    </Link>
                                                </li> */}
                                                <li>
                                                    <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share social-icon">
                                                        <i className="icon-twitter-blue"></i>
                                                    </TwitterShareButton>
                                                </li>
                                                <li>
                                                    <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share social-icon">
                                                        <i className="icon-facebook-blue"></i>
                                                    </FacebookShareButton>
                                                </li>
                                                <li>
                                                    <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share social-icon">
                                                        <i className="icon-linkedin-blue"></i>
                                                    </LinkedinShareButton>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='rgt-content'>
                                    <div className='contact-box d-xl-block'>
                                        <ContactDetails contactData={contactData} />
                                    </div>
                                    <div className='news-box'>
                                        <div className='recent-news'>
                                            <h4>Recent News</h4>
                                            <RecentNews {...news_data}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </AnimationOnScroll>
    )
}

export default AreaContent